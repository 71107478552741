
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    position: relative;
    background-color: #5667ab;
    h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;
        color: #fff;
        @include lg {
            font-size: 30px;
            line-height: 45px;
        }
    }

    p {
        font-size: 16px;
        line-height: 27px;
        color: #fff;
        margin-top: 19px;
       
        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
}
