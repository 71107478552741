
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    position: relative;

    h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 30px;
        color: #304399;
        @include lg {
            font-size: 30px;
            line-height: 45px;
        }
    }

    p {
        font-size: 16px;
        line-height: 27px;
        color: #4f4f4f;
        // margin-top: 30px;
        @include lg {
           
            font-size: 18px;
            line-height: 32px;
        }
    }
}
.wrapperBg {
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -1;

        background-color: #f7f7f7;
    }
}
