
            @import 'src/styles/mixins.scss';
        
.mainSection {
    margin: 0 auto;

    text-align: center;
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;

        background-color: #304399;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: -10;
    }

    p {
        color: #fff;
        font-size: 18px;
        max-width: 1017px;
        margin: 0 auto;
        @include lg {
            font-size: 25px;
            line-height: 44px;
        }
    }
}
.animation {
    color: #304399;
    background-color: #51e2d5;
    transition: all 0.3s ease-in-out;
}
.animation:hover {
    transform: translateY(-7px);
    transition: all 0.3s ease-in-out;
}
